// @flow

import React from 'react'
import type { Node } from 'react'

import styled from 'styled-components'
import cx from 'classnames'
import { rgba } from 'polished'

type Props = {
  type: 'submit' | 'text',
  value: string,
  onClick: () => void,
  color?: string,
  className?: string,
  disabled?: boolean,
}

function PillButtonSubmit({
  type,
  value,
  onClick,
  color,
  className,
  disabled,
}: Props): Node {
  return (
    <StyledInput
      className={cx(
        'px-8 py-4 block rounded-full text-center no-underline font-semibold font-sm',
        color === 'salmon' ? 'text-white' : 'black',
        color && `bg-${color}`,
        disabled && 'opacity-50',
        !disabled && 'cursor-pointer',
        className,
      )}
      type={type}
      value={value}
      disabled={disabled}
      onClick={onClick}
    />
  )
}
PillButtonSubmit.defaultProps = {
  color: 'salmon',
  className: null,
  disabled: false,
}

export default PillButtonSubmit

const StyledInput = styled.input`
  text-shadow: 0 1px 0 ${rgba('#000', 0.05)};
  box-shadow: 0 2px 4px ${rgba('#000', 0.11)},
    inset 0 1px 2px ${rgba('#fff', 0.19)};
`
