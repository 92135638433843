// @flow

import * as React from 'react'
import './trip-advisor-reviews.css'

function TripAdvisorReviews(): React.Element<'div'> {
  return (
    <div
      id="TA_selfserveprop532"
      className="TA_selfserveprop p-2 bg-white inline-block rounded"
    >
      <ul id="azB6BZ6KHw" className="TA_links ARLHAO">
        <li id="fXKkrWTF4f" className="hDLzVKxUJ7LZ">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.tripadvisor.com/"
          >
            <img
              src="https://www.tripadvisor.com/img/cdsi/img2/branding/150_logo-11900-2.png"
              alt="TripAdvisor"
            />
          </a>
        </li>
      </ul>
    </div>
  )
}

export default TripAdvisorReviews
