// @flow

import type { Node, ComponentType } from 'react'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import styled from 'styled-components'
import theme from '../../../../tailwind'

type BaseProps = {
  backgroundImage: string,
}

type QueryResponse = {|
  homepage: {
    featuredImage: {
      image: {
        src: string,
      },
    },
  },
|}

function HomepageHero(): Node {
  const data: QueryResponse = useStaticQuery(graphql`
    query HomepageHeroQuery {
      homepage: contentfulPage(slug: { eq: "Home" }) {
        featuredImage: primaryWideFormatImage {
          image: fixed(width: 2000, quality: 80) {
            src
          }
        }
      }
    }
  `)

  if (
    !data &&
    !data.homepage &&
    !data.homepage.featuredImage &&
    !data.homepage.featuredImage.image &&
    !data.homepage.featuredImage.image.src
  ) {
    return null
  }

  return <Base backgroundImage={data.homepage.featuredImage.image.src} />
}

export default HomepageHero

const Base: ComponentType<BaseProps> = styled.div`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: center;
  background-size: cover;
  height: 20rem;

  @media screen and (min-width: ${theme.screens.sm}) {
    height: 35rem;
    background-position: 50% 40%;
  }
`
