// @flow

import React from 'react'
import type { Node } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import theme from '../../../../tailwind'
import routes from '../../../routes'

import { PillButtonLink } from '../../../components/buttons'
import { BodyText } from '../../../components/typography'
import SectionTitle from '../../../components/section-title'

import type { FindableContentfulPageImage } from '../../../types'
import { filterContentfulPageImageByTitle } from '../../../helpers/contentfulPageImages'

import Wave from './wave'

type QueryResponse = {|
  homepageIntro: {
    images: FindableContentfulPageImage[],
  },
|}

type HomepageIntroProps = {
  data: QueryResponse,
}

function HomepageIntroContent({ data }: HomepageIntroProps) {
  const imageNode = filterContentfulPageImageByTitle(
    'poolside',
    data.homepageIntro.images,
  )
  return (
    <div id="intro">
      <div className="bg-purple text-white relative">
        <div className="pt-12 pb-2 md:pt-16 md:pb-12 px-8">
          <div className="w-full max-w-xl mx-auto">
            <SectionTitle className="pb-4">
              The Perfect
              <br className="md:hidden" /> Vacation Rental
            </SectionTitle>
            <div className="md:w-3/5">
              <BodyText className="pb-5 leading-normal text-purple-lightest">
                Cradled in the lively, peaceful and balmy town of Fort Myers
                Beach Florida, Cottages of Paradise Point makes a perfect
                vacation home getaway.
              </BodyText>
            </div>
            <div className="inline-flex">
              <PillButtonLink to={routes.cottages}>
                View Cottages
              </PillButtonLink>
            </div>
          </div>
        </div>
        <WaveContainer className="z-10">
          <StyledWave color={theme.colors.purple} />
        </WaveContainer>
      </div>
      <StyledFullscreenContainer className="pb-4 bg-white">
        {imageNode && imageNode.image && imageNode.image.src ? (
          <img
            className="w-full"
            src={imageNode.image.src}
            alt={imageNode.description || ''}
          />
        ) : null}
      </StyledFullscreenContainer>
    </div>
  )
}

function HomepageIntro(): Node {
  const data: QueryResponse = useStaticQuery(graphql`
    query HomepageIntroQuery {
      homepageIntro: contentfulPage(slug: { eq: "Home" }) {
        images {
          title
          image: fixed(width: 1800, quality: 60) {
            src
          }
        }
      }
    }
  `)

  return <HomepageIntroContent data={data} />
}

export default HomepageIntro

const StyledWave = styled(Wave)`
  height: 100px;
  width: 100%;
`
const StyledFullscreenContainer = styled.div`
  overflow: hidden;

  @media screen and (min-width: ${theme.screens.md}) {
    height: 500px;
  }

  img {
    object-fit: cover;
    object-position: top;
  }
`
const WaveContainer = styled.div`
  left: 0;
  position: absolute;
  right: 0;
  height: 6rem;
`
