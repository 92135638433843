// @flow

import * as React from 'react'

import routes from '../../../routes'

import { BodyText } from '../../../components/typography'
import SectionTitle from '../../../components/section-title'
import { PillButtonLink } from '../../../components/buttons'

function HomepageAboutParadisePoint(): React.Element<'section'> {
  return (
    <section id="about-paradise-point" className="bg-white">
      <div className="px-6">
        <div className="w-full max-w-xl mx-auto">
          <div className="md:w-3/5">
            <SectionTitle className="pb-4 text-salmon">
              Paradise Point
            </SectionTitle>
            <BodyText className="leading-normal text-grey-darkest pb-6">
              Paradise Point is a lovely little getaway on the much desired
              tranquil north end of Ft. Myers Beach. It’s quiet enough to get
              some peace and yet brimming with local life. You will be sure to
              find a perfect balance here of relaxation and fun in the sun!
            </BodyText>
            <div className="inline-block">
              <PillButtonLink to={routes.paradisePoint}>Explore</PillButtonLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomepageAboutParadisePoint
