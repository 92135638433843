// @flow

import type { Node } from 'react'
import React from 'react'
import Layout from '../components/layout'

import {
  HomepageHero as Hero,
  HomepageIntro as Intro,
  HomepageAboutParadisePoint as AboutParadisePoint,
  HomepageThingsToDo as ThingsToDo,
  HomepageContact as Contact,
} from '../scenes/homepage'

function Homepage(): Node {
  return (
    <Layout overlayHeader>
      <div className="bg-white">
        <Hero />
        <div className="pb-16">
          <Intro />
        </div>
        <div className="pb-16 bg-white">
          <AboutParadisePoint />
        </div>
        <div className="pb-16">
          <ThingsToDo />
        </div>
        <Contact />
      </div>
    </Layout>
  )
}

export default Homepage
