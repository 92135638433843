// @flow
import type { Element } from 'react'
import React from 'react'

type Props = {
  color: string,
  className?: string,
}

function Wave({ color, className }: Props): Element<'svg'> {
  return (
    <svg
      className={className}
      width="1920px"
      height="99px"
      viewBox="0 0 1920 99"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      version="1.1"
    >
      <path
        d="M0 73.628c41.435 8.253 246.555 40.442 441.358 0 129.869-26.961 259.66-26.961 389.376 0 112.361 25.025 230.652 22.22 354.874-8.415 186.332-45.951 379.651-10.847 425.364 0 140.503 33.342 195.877 53.985 309.028 0V0H0v73.628z"
        fillRule="nonzero"
      />
    </svg>
  )
}
Wave.defaultProps = {
  className: undefined,
}

export default Wave
