// @flow

import type { FindableContentfulPageImage as Image } from '../types'

const contentfulPageImage = (imageTitle: string) => (img: Image) => {
  return img.title.toLowerCase() === imageTitle
}

export const filterContentfulPageImageByTitle = (
  searchTitle: string,
  images: Image[],
): Image => {
  const image = images.find(contentfulPageImage(searchTitle))

  if (!image) {
    throw new Error('Unable to load `image.src`')
  }

  return image
}

export default filterContentfulPageImageByTitle
