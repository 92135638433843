// @flow

import * as React from 'react'
import cx from 'classnames'

import { Heading2 } from '../typography'

type Props = {
  children: React.Node,
  className?: string,
}

function SectionTitle({ children, className }: Props): React.Node {
  return (
    <Heading2
      className={cx(
        'md:text-2xl lg:text-3xl leading-tight font-bold',
        className,
      )}
    >
      {children}
    </Heading2>
  )
}
SectionTitle.defaultProps = {
  className: null,
}

export default SectionTitle
