// @flow

import React from 'react'
import type { Node } from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { rgba } from 'polished'

import theme from '../../../../tailwind'

import SectionTitle from '../../../components/section-title'
import { BodyText } from '../../../components/typography'
import { filterContentfulPageImageByTitle } from '../../../helpers/contentfulPageImages'

import type { FindableContentfulPageImage } from '../../../types/contentful'

type QueryResponse = {|
  homepageThingsToDo: {
    images: FindableContentfulPageImage[],
  },
|}

type ThingsToDoPureProps = {
  image: {
    src: string,
    alt: string,
  },
}

function ThingsToDoPure({ image }: ThingsToDoPureProps) {
  return (
    <section id="things-to-do">
      <div className="relative">
        <GradientOverlay className="absolute pin-b pin-x z-10" />
        <div className="h-full w-full">
          <img className="w-full" src={image.src} alt={image.alt} />
        </div>
      </div>
      <div className="px-8">
        <div className="w-full max-w-xl mx-auto">
          <div className="md:w-3/5 pt-12">
            <SectionTitle className="pb-4 text-salmon">
              Time for fun!
            </SectionTitle>
            <BodyText className="leading-normal text-grey-darkest pb-6">
              Here at Paradise Point one never runs out of things to do—the
              beach, the shopping, and the night-life. We like to think that we
              offer our guests indulgences—special touches and fine details that
              the competition just doesn’t think about. We love to go that extra
              mile so you feel like you are at home.
            </BodyText>
          </div>
        </div>
      </div>
    </section>
  )
}

function HomepageThingsToDo(): Node {
  const data: QueryResponse = useStaticQuery(graphql`
    query HomepageThingsToDoQuery {
      homepageThingsToDo: contentfulPage(slug: { eq: "Home" }) {
        images {
          title
          description
          image: fixed(width: 1800, quality: 60) {
            src
          }
        }
      }
    }
  `)

  if (!data && !data.homepageThingsToDo && !data.homepageThingsToDo.images) {
    return null
  }

  const imageNode = filterContentfulPageImageByTitle(
    'sunny-driveway',
    data.homepageThingsToDo.images,
  )

  return (
    <ThingsToDoPure
      image={{
        src: imageNode.image.src,
        alt: imageNode.description || '',
      }}
    />
  )
}

export default HomepageThingsToDo

const GradientOverlay = styled.div`
  height: 100%;

  background-image: linear-gradient(
    ${rgba(theme.colors.white, 0)} 33%,
    ${rgba(theme.colors.white, 1)}
  );

  @media screen and (min-width: ${theme.screens.md}) {
    background-image: linear-gradient(
      ${rgba(theme.colors.white, 0)} 67%,
      ${rgba(theme.colors.white, 1)} 88%
    );
  }
`
