// @flow

import * as React from 'react'

import routes from '../../../routes'

import { PillButtonLink } from '../../../components/buttons'
import { BodyText } from '../../../components/typography'
import SectionTitle from '../../../components/section-title'

import CustomerReviews from '../../../components/trip-advisor'

function HomepageContact(): React.Element<'section'> {
  return (
    <section
      id="contact"
      className="bg-white border-t border-solid border-grey-light bg-purple text-white"
    >
      <div className="py-12 md:pt-16 md:pb-12 px-8">
        <div className="w-full max-w-xl mx-auto">
          <div className="md:flex md:items-center">
            <div className="pb-12 md:pb-0">
              <div className="md:w-3/5">
                <SectionTitle className="pb-4">Contact Us</SectionTitle>
                <BodyText className="leading-normal pb-6 text-purple-lightest">
                  Contact Us for more information or to book your vacation at
                  Cottages of Paradise Point today; where you’ll find solace in
                  our relaxing Fort Myers Beach Cottages.
                </BodyText>
              </div>
              <div className="inline-block">
                <PillButtonLink to={routes.contact} color="salmon">
                  Contact Us
                </PillButtonLink>
              </div>
            </div>
            <div>
              <CustomerReviews />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomepageContact
